import i18n from '../i18n';
import {getCurrency} from '../utils';
import {getProductSearchCondition} from '../api/product';

export default {
  data() {
    return {
      cityRecommends: [],
      productClassifications: [],
      businesses: [],
      params: {
        keyword: '', // 关键词
        cities: [], // 城市ids
        classifications: [], // 分类ids
        currentClassification: '', // 当前分类id
        minPrice: '', // 最小价格
        maxPrice: '', // 最大价格
        days: [], // 天数 1:一日；24：二日至四日；57：5日至7日；77：7日以上
        businesses: [], // 商户ids
        currentMonth: '', // 当前月份 2020-02
        minDate: '', // 最小日期 2020-02-02
        maxDate: '', // 最大日期 2020-02-02
        orderType: '', // 排序 asc：往高 desc：往低
        moreCondition: [] // freeTravel：自由行 tourGroup：旅行社；cancelOrder：是否可以取消预订
      },
      price: [0, 80000],
      times: [
        {name: i18n.t('DayTrip'), id: '1'}, // 一日遊
        {name: i18n.t('ShortTermTravel'), id: '24'}, // 短線行程2～4天
        {name: i18n.t('MidlineItinerary'), id: '57'}, // 中線行程5～7天'
        {name: i18n.t('LongTermTravel'), id: '77'} // 長線行程7天以上
      ],
      listType: [
        {name: i18n.t('FreeTravel'), id: 'freeTravel'}, // 自由行
        {name: i18n.t('TourGroup'), id: 'tourGroup'} // 旅行团
        // {name: i18n.t('CanCancelReservations'), id: 'cancelOrder'} // 可取消预订 無用
      ],
      sortOptions: [
        {value: 'asc', label: i18n.t('PriceFromLowToHigh')},
        {value: 'desc', label: i18n.t('PriceFromHighToLow')}
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      },
      dates: null,
      currency: ''
    };
  },
  computed: {
    slectedList() {
      const cities = this.cityRecommends.filter(item => this.params.cities.includes(item.businessId)).map(item => {
        item.type = 'cities';
        return item;
      });
      const classifications = this.productClassifications.filter(item => this.params.classifications.includes(item.code)).map(item => {
        item.type = 'classifications';
        return item;
      });
      const days = this.times.filter(item => this.params.days.includes(item.id)).map(item => {
        item.type = 'days';
        return item;
      });
      const businesses = this.businesses.filter(item => this.params.businesses.includes(item.userId)).map(item => {
        return {id: item.userId, name: item.businessName, type: 'businesses'};
      });
      return [...cities, ...classifications, ...days, ...businesses];
    }
  },
  created() {
    this.currency = getCurrency();
    this.selectFilterData('cities', this.$route.query.cityId);
    this.selectFilterData('classifications', this.$route.query.classification);
    getProductSearchCondition().then(res => {
      Object.assign(this.$data, res.value || {});
    });
  },
  methods: {
    // 选择/取消过滤条件数据
    selectFilterData(selectedListKey, id) {
      if (id) {
        if (this.params[selectedListKey].includes(id)) {
          this.params[selectedListKey].splice(this.params[selectedListKey].indexOf(id), 1);
        } else {
          this.params[selectedListKey].push(id);
        }
        this.getList();
      }
    },
    // 全选选中/全部取消选中
    allSelect(selectedListKey, idName, list) {
      if (this.params[selectedListKey].length === list.length) {
        this.params[selectedListKey] = [];
      } else {
        this.params[selectedListKey] = list.map(item => item[idName]);
      }
      this.getList();
    },
    // 删除某个选中的
    deleteSelectedItem(item) {
      this.params[item.type].splice(this.params[item.type].indexOf(item.id), 1);
      this.getList();
    },
    // 清除选项
    clearOptions() {
      this.params = this.$options.data().params;
      this.price = this.$options.data().price;
      this.dates = null;
      this.getList();
    },
    // 四大分类选择
    selectClassification(id) {
      this.params.classifications = [];
      if (this.params.currentClassification === id) {
        this.params.currentClassification = [];
      } else {
        this.params.currentClassification = id;
      }
      this.getList();
    },
    // 切换排序
    changeSort(value) {
      this.params.orderType = value;
      this.getList();
    },
    // 切换日期
    changeDates() {
      this.params.currentMonth = '';
      if (this.$refs.month) {
        this.$refs.month.clearMonth();
      }
      this.getList();
    },
    // 改变价格
    changePrice() {
      this.getList();
    },
    // 改变月份
    changeMonth(value) {
      this.params.currentMonth = value.replace('/', '-');
      this.dates = null;
      this.getList();
    },
    // 请求参数
    getParams() {
      let params = JSON.parse(JSON.stringify(this.params));
      for (let key of Object.keys(params)) {
        if (Array.isArray(params[key])) {
          params[key] = params[key].join(';');
        }
      }
      params.minDate = (this.dates || [])[0] || '';
      params.maxDate = (this.dates || [])[1] || '';
      params.minPrice = this.price[0];
      params.maxPrice = this.price[1];
      return params;
    }
  }
};
