import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// Get In Progress Competition
export function getInProgressCompetition() {
  return request({
    url: `/competition/getCompetition/${language}/InProgress`,
    method: 'get'
  });
}
export function getCompetitionByID(id) {
  return request({
    url: `/competition/getCompetitionByID/${language}/${id}`,
    method: 'get'
  });
}
export function checkHKIDPhone() {
  return request({
    url: `/user/my/info/checkHKIDPhone`,
    method: 'get'
  });
}
export function signUpCompetition(competitionID, age) {
  return request({
    url: `/competition/signUpCompetition/${competitionID}/${age}`,
    method: 'post'
  });
}
export function checkSignUpCompetition(competitionID) {
  return request({
    url: `/competition/checkSignUpCompetition/${competitionID}`,
    method: 'get'
  });
}
export function getUserCompetitionList() {
  return request({
    url: `/competition/getUserCompetitionList/${language}`,
    method: 'get'
  });
}
