import request from '@/utils/request';
import {getLocale} from '../utils/language';
import {getCurrency} from '../utils';

const language = getLocale() || 'zh-hk';
const currency = getCurrency();

// 获取产品列表
export function getProductList(data) {
  data.currencyCode = currency;
  return request({
    url: `/product/home/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取产品首页banner
export function getProductBanner() {
  return request({
    url: '/group/home/banner/list',
    method: 'get'
  });
}

// 获取产品详情
export function getProductDetail(productId) {
  return request({
    url: `/product/home/details/${productId}/${language}`,
    method: 'get'
  });
}

// 获取产品路线详情
export function getProductLineDetail(lineId) {
  return request({
    url: `/product/home/line/details/${lineId}/${language}`,
    method: 'get'
  });
}

// 获取产品多媒体列表
export function getProductMediaList(productId) {
  return request({
    url: `product/home/media/list/${language}/${productId}`,
    method: 'get'
  });
}

// 获取产品列表及搜索列表增加条件接口
export function getProductSearchCondition() {
  return request({
    url: `/product/home/search/condition/${language}`,
    method: 'get'
  });
}

// 根据路线及日期获取航班资料
export function getProductFlight(lineId, departureDate) {
  return request({
    url: `/product/home/flight/${lineId}/${language}/${departureDate}`,
    method: 'get'
  });
}

// 获取产品价格列表
export function getProductPriceList(productId) {
  return request({
    url: `/product/home/price/list/${productId}/${language}`,
    method: 'get',
    params: {currencyCode: currency}
  });
}

// 按日期及路线获取价格详情
export function getProductPrice(lineId, priceDate) {
  return request({
    url: `/product/home/line/price/${lineId}/${priceDate}`,
    method: 'get',
    params: {currencyCode: currency}
  });
}

// 搜索产品
export function searchProduct(data) {
  data.currencyCode = currency;
  return request({
    url: `/product/home/search/list/${language}`,
    method: 'get',
    params: data
  });
}

// 获取产品评论列表（分页）
export function getProductCommentList(productId, data) {
  return request({
    url: `/product/comment/list/${productId}`,
    method: 'get',
    params: data
  });
}
