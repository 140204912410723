<template>
  <router-link class="theme-item " :to="{name: 'competitionProductDetail', params: {id:datas.productId}}">
    <div class="cover ani-scale">
      <img class="img" :src="datas.photoUrl" alt=""/>
    </div>
    <div class="infos">
      <div class="cl">
<!--        <star class="fl" :value="datas.score|starToPercentage"/>-->
        <div class="fr" style="width:70px;">
          <div class="travel-agency-name" :title="datas.businessName">{{datas.businessName}}</div>
        </div>
      </div>
      <div class="title">
        {{datas.title}}
      </div>
      <div class="intro">
        {{datas.introduction}}
      </div>
      <div class="cl m-t">
        <div class="fl">
<!--          <i class="icons i-comment"></i>-->
          <span class="fcs1">{{$t('Views')}}:{{datas.views||0}}</span>
        </div>
        <div class="fr">
<!--          <i class="icons i-cart"></i>-->
          <span style=" color: #ff6f61; font-weight: bold">{{$t('Vote(s)')}}:{{datas.votes}}</span>
<!--          <span class="fcs1">{{$t('Buy')}}:{{datas.buyNumber|interceptNumber}}</span>-->
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
  import {mapGetters} from 'vuex';
  import {cancelCollection, productCollection} from '../../../api/collection';

  export default {
    name: 'CompetitionItem',
    props: {
      target: {
        type: String,
        default: '_self'
      },
      datas: Object,
      index: Number,
      list: Array,
      isCollection: Boolean
    },
    computed: {...mapGetters(['token'])},
    components: {},
    methods: {
      // 跳转到详情页
      openDetail() {
        const id = this.datas.productId;
        const routeUrl = this.$router.resolve({name: 'TourismDetail', params: {id}});
        if (this.target === '_self') {
          this.$router.push(routeUrl.href);
        } else {
          window.open(routeUrl.href, '_blank');
        }
      },
      // 收藏/取消收藏
      setCollection() {
        if (!this.token) {
          this.$message.warning(this.$t('Please sign in'));
          this.$router.push({name: 'Login', query: {redirect: this.$route.fullPath}});
        } else {
          let list = [...this.list];
          const isCollection = this.isCollection || this.datas.isCollection === 'Y';
          if (isCollection) {
            // 取消收藏
            cancelCollection(this.datas.productId).then(res => {
              if (this.isCollection) {
                list.splice(this.index, 1);
              } else {
                list[this.index].isCollection = 'N';
              }
              this.$emit('update:list', list);
            }).catch(() => {
            });
          } else {
            // 收藏
            productCollection(this.datas.productId).then(res => {
              list[this.index].isCollection = 'Y';
              this.$emit('update:list', list);
            }).catch(() => {
            });
          }
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .theme-item{
    display:block;float:left;width:220px;margin-right:15px;margin-left:15px;margin-bottom:15px;overflow:hidden;cursor:pointer;border-radius:4px;box-shadow:0 2px 9px 0 rgba(255, 115, 101, 0.2);min-height: 237px;
    .cover{position:relative;height:148px;}
    .infos{padding:8px;}
    .travel-agency-name{white-space:nowrap;text-overflow:ellipsis;text-align:right;color:#999;}
    .title{
      font-size: 20px;display:-webkit-box;height:30px;margin-top:8px;line-height:22px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;
      span{padding-right:8px;color:#ff6f61;}
    }
    .intro{
      text-indent: 5px;font-size: 12px;display:-webkit-box;height:44px;margin-top:8px;line-height:22px;overflow:hidden;-webkit-line-clamp:2;-webkit-box-orient:vertical;
      span{padding-right:8px;color:#ff6f61;}
    }
    .m-t{margin-top:8px;}
    .fcs1{display:inline-block;vertical-align:middle;color:#999;}
  }
  .btn-collection{position:absolute;top:8px;right:8px;padding:8px;line-height:1;}
  .icons{margin-right:4px;vertical-align:middle;}
  .icon-heart{width:16px;height:16px;}
</style>
