<template>
  <div>
    <img :src="this.competition.photoURL" class="headerImage" :alt="中國旅行路線設計比賽"/>
    <div>
      <p class="h1" style="text-align: center; ">中國旅行路線設計比賽</p>
      <p class="intro">中港通關在即，香港市民極盼回中國大陸遊玩和交流。為了滿足香港人的旅遊需求， UNU Travel特舉辦「中國旅行路線設計比賽」， 迎接即將洶湧而至的中國旅遊浪潮，促進兩地往來，振興旅遊業的發展。</p>
      <br/>
      <p class="signUpTime">報名時間：待定</p>
      <br/>
      <div class="center">
        <a v-if="signedUp == '0'" class="button" @click="openUserInfoModal">{{$t('signUp')}}</a>
        <a v-else class="buttonDisabled button">{{$t('You had already signed up')}}</a>
        <a href="#competitionDetail" class="button">比賽詳情</a>
      </div>
    </div>
    <br/>
    <div style="background: linear-gradient(177.78deg, rgba(250, 252, 255, 0) 1.84%, rgba(255, 215, 0, 0.04) 12.29%, rgba(255, 215, 0, 0.19) 51.8%, rgba(255, 215, 0, 0.04) 90.77%, rgba(248, 251, 255, 0) 98.14%);">
      <div>
        <p class="h2" style="text-align: center; color: #000000">設有「我最喜愛路線設計大獎」及「旅行社青睞大獎」兩大獎項</p>
        <div class='parent'>
          <div class='child'>
            <span style="font-size: 36px; color: #F47E59; font-weight: 800;">我最喜愛路線設計大獎</span><br/>
            <p style="padding: 5px; font-size: 20px; line-height: 45px">由大眾選出最喜愛的路線設計入圍50強即可獲得免費旅遊機會</p>
            <img width="200px" height="200px" src="https://unu-oss.oss-cn-hongkong.aliyuncs.com/20211221/01/007-rating-4%201.png">
          </div>
          <div class='child'>
            <span style="font-size: 36px; color: #F47E59; font-weight: 800;">旅行社青睞大獎</span><br/>
            <p style="padding: 5px; font-size: 20px; line-height: 45px">所有參賽的有特色作品亦有機會受旅行社青睞，採用之作品成行的話，參賽者將可免費隨團旅行</p>
            <img width="200px" height="200px" src="https://unu-oss.oss-cn-hongkong.aliyuncs.com/20211221/01/026-like-1%201.png">
          </div>
        </div>
      </div>
    </div>

    <div>
      <div>
        <p class="hd">時間線</p>
        <div class='parent'>
          <div class="timeLineChild">
            <div><img src="https://unu-oss.oss-cn-hongkong.aliyuncs.com/20211221/01/008-tv%201.png"/></div>
            發佈會<br/>
            待定
          </div>
          <div class="timeLineChild">
            <div><img src="https://unu-oss.oss-cn-hongkong.aliyuncs.com/20211221/01/048-event%201.png"/></div>
            報名截止日期<br/>
            待定
          </div>
          <div class="timeLineChild">
            <div><img src="https://unu-oss.oss-cn-hongkong.aliyuncs.com/20211221/01/022-audience%201.png"/></div>
            第一輪投票截止日期<br/>
            待定
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <p class="hd" E>參賽作品</p>
      <br/>
      <div class="cl tourspage">
        <empty v-if="list.length === 0" :title="$t('NoData')"/>
        <competition-item v-for="(item,index) of list"
                          :datas="item"
                          :index="index"
                          :list.sync="list"
                          :key="item.productId"/>
      </div>
      <router-link class="comingsoon"
                   v-if="list.length > 0"
                   :to="{name:'competitionProductList',params:{id:this.competitionID}}">更多作品</router-link>
    </div>

    <div style="margin: auto; padding: 10px; background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 215, 0, 0.04) 12.87%, rgba(255, 215, 0, 0.15) 44.13%, rgba(255, 215, 0, 0.06) 72.57%, rgba(247, 250, 255, 0) 100%);">
      <p class="hd" id="competitionDetail">比賽詳情</p>
      <br/>
      <div style="margin: 0 10vw 0 10vw; line-height: 30px" >
        <span style="font-size: 20px; font-weight: bold">參賽方法</span>
        <p>※ 請閱讀比賽條款及規則，網上報名及網上提交參賽行程設計方案。</p>
        <p>※ 個人名義報名，參加者須持有香港身份證</p>
        <br/>
        <span style="font-size: 20px; font-weight: bold">投票方式</span>
        <p>※ 第一輪 每人5票，選出50強，投票截止日期為 待定。</p>
        <p> ※ 第二輪 每人3票，選出10強，投票截止日期為 待定。</p>
        <p> ※ 第三輪 每人1票，選出3甲，投票截止日期為 待定。</p>
        <br/>
        <span style="font-size: 20px; font-weight: bold">獎品</span>
        <p style="font-weight: bold">「我最喜愛路線獎」</p>
        <p>※ 50強</p>
        <p>※ 10強</p>
        <p>※ 冠、亞、季軍</p>
        <p style="font-weight: bold">所有獎品詳情將於 待定 發佈會公佈，敬請留意。</p>
        <br/>
        <p style="font-weight: bold">「旅行社青睞大獎」</p>
        <p>所有參賽的有特色作品亦有機會受旅行社青睞，採用之作品成行的話，參賽者將可免費隨團旅行。</p>
        <br/>
          <span style="font-size: 20px; font-weight: bold">比賽條款及規則 </span>
          <p>1. 參賽者必須填寫真實之個人資料。</p>
          <p>2. 參賽路線必須未曾在其他路線設計比賽中獲獎，亦非現有旅行社路線。</p>
          <p>3. 每位參賽者只限提交一個作品。</p>
          <p>4. 如有類同作品，最先提交者可保留，其餘需作出修訂或取消。</p>
          <p>5. 參賽設計方案中所包含的照片、圖片及文字必須為參賽者之個人原創作品，且擁有該作品的全部版權。</p>
          <p>6. 凡參加本比賽者，均等同已無限制及無保留地授權予主辦機構或其代表，並有權在任何情況之下使用、展示、刊出及發佈該作品而毋須另行徵得參賽者的同意及另外支付費用。</p>
          <p>7. 所有得獎者將被邀請出席頒獎禮。</p>
          <p>8. 主辦機構對本參賽的規則有權作出修訂而毋須另外通告，主辦機構亦保留本比賽的最後解釋權及決定權，並有權拒絕任何作品參賽或取消其參賽資格而無須作出解釋。</p>
          <p>9. 如參賽者因參加本比賽或因為主辦機構使用、展示、刊出及發佈其作品而引致第三者的任何索償或訴訟，參賽者須自行承擔所有責任。</p>
      </div>

    </div>

    <div class="pageContent">

      <div class="center">

      </div>
    </div>

<!--    <div style="background-color: #fff1e6">-->
<!--      <div class="pageContent">-->
<!--        <div class="hd">{{$t('Popular')}}</div>-->
<!--        <Item :list.sync="competitionInProgress" />-->
<!--      </div>-->
<!--    </div>-->

    <modalJS name="userInfo" width="400px" :adaptive="true" height="auto">
      <div class="modalHeader">
        <div class="center buttonText">{{$t('signUp')}}</div>
      </div>
      <div class="modalContent">
        <p class="content">{{$t('To verify your identity while collecting the prize. Please provide your full name shown on your id card, HKID last four digits, including the digit/letter inside the bracket and your phone number.')}}</p>
        <div class="inputForm">
          <label for="name" v-if="!hasHKID">{{$t('Name')}}</label>
          <input v-if="!hasHKID" id="name" type="text" autocomplete="off" minlength="1" :placeholder="$t('Name')" v-model.trim="fullName">
          <label for="hkid" v-if="!hasHKID">{{$t('hkid')}}</label>
          <input v-if="!hasHKID" id="hkid" type="text" autocomplete="off" minlength="4" maxlength="8" :placeholder="$t('hkid')" v-model.trim="hkid">
          <label for="phone" v-if="!hasHKID">{{$t('Phone')}}</label>
          <input v-if="!hasHKID" id="phone" type="text" pattern="\d*" autocomplete="off" minlength="8" maxlength="11" :placeholder="$t('Phone')" v-model.trim="phone">
          <label for="age">{{$t('Age')}}</label>
          <input id="age" type="text" pattern="\d*" autocomplete="off" minlength="1" maxlength="3" :placeholder="$t('Age')" v-model.trim="userAge">
        </div>

      </div>
      <div class="modalFooter modalCenter">
        <a class="modalButton"  style="background-color: #ffd6a5" @click="hideModal">{{$t('Cancel')}}</a>
        <a class="modalButton" style="background-color: #caffbf" @click="onSubmitSignUp">{{$t('Submit')}}</a>
      </div>

    </modalJS>
  </div>

</template>
<script>
import {
  checkHKIDPhone,
  checkSignUpCompetition,
  getCompetitionByID,
  getProductList,
  signUpCompetition
} from '../../api/competition';
import {mapGetters} from 'vuex';
import {updateMyInfo} from '../../api/user';
import CompetitionItem from './components/competitionItem';
import Empty from '../../components/Empty';
import tours from '../../mixins/tours';

export default {
  name: 'competitionDetail',
  mixins: [tours],
  data() {
    return {
      popularWorks: [],
      competitionID: '',
      competition: Object,
      signedUp: String,
      hkid: '',
      phone: '',
      fullName: '',
      userAge: 0,
      hasHKID: false,
      totalPages: 1,
      list: []
    };
  },
  components: {CompetitionItem, Empty},
  mounted() {
    this.competitionID = this.$route.query.id || '9876c08e407743938cce9kkkkkkrrrrk2a255d9c545';
    this.getCompetitionByID();
    this.checkSignUpCompetition();
    this.getList();
  },
  created() {
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    getList(page = 1) {
      const data = {page, perPage: 5, ...this.getParams(), orderBy: 'votes'};
      getProductList(data, this.competitionID).then(res => {
        const {value} = res;
        const list = value.list || [];
        this.list = list;
        this.totalPages = value.totalPages;
      });
    },
    showModal() {
      this.$modal.show('userInfo');
    },
     hideModal() {
      this.$modal.hide('userInfo');
    },
    checkSignUpCompetition() {
      checkSignUpCompetition(this.competitionID).then((res) => {
        this.signedUp = res.value;
      });
    },
    getCompetitionByID() {
      getCompetitionByID(this.competitionID).then((res) => {
        Object.assign(this.$data, res.value || {});
      });
    },
    onSubmitUpdateInfo() {
      if (!this.token) {
        const routeUrl = this.$router.resolve({path: this.$route.fullPath, query: {formdata: 'JSON.stringify(data)'}});
        this.$message.warning(this.$t('Please sign in'));
        this.$router.push({name: 'Login', query: {redirect: routeUrl.href}});
      } else {
        const datas = {
          phone: this.phone,
          hkid: this.hkid,
          fullName: this.fullName
        };
        updateMyInfo(datas).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
          this.hideModal();
          this.hasHKID = true;
          signUpCompetition(this.competition.id, this.userAge).then(res => {
            this.checkSignUpCompetition();
          });
        }).catch(() => {
        });
      }
    },
    openUserInfoModal() {
      if (!this.token) {
        const routeUrl = this.$router.resolve({path: this.$route.fullPath, query: {formdata: 'JSON.stringify(data)'}});
        this.$message.warning(this.$t('Please sign in'));
        this.$router.push({name: 'Login', query: {redirect: routeUrl.href}});
      } else {
        checkHKIDPhone().then(res => {
          if (res.value === 'OK') {
            this.hasHKID = true;
          } else {
            this.hasHKID = false;
          }
          this.$modal.show('userInfo');
        });
      }
    },
    onSubmitSignUp() {
      if (!this.token) {
        const routeUrl = this.$router.resolve({path: this.$route.fullPath, query: {formdata: 'JSON.stringify(data)'}});
        this.$message.warning(this.$t('Please sign in'));
        this.$router.push({name: 'Login', query: {redirect: routeUrl.href}});
      } else {
        if (!this.hasHKID) {
          this.onSubmitUpdateInfo().then(res => {
            checkHKIDPhone().then(res => {
              if (res.value === 'OK') {
                signUpCompetition(this.competition.id, this.userAge).then(res => {
                  this.checkSignUpCompetition();
                });
              } else {
                this.$modal.show('userInfo');
              }
            });
          });
        } else {
          checkHKIDPhone().then(res => {
            if (res.value === 'OK') {
              signUpCompetition(this.competition.id, this.userAge).then(res => {
                this.checkSignUpCompetition();
                this.hideModal();
              });
            } else {
              this.$modal.show('userInfo');
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.parent {
  margin: 3%;
  text-align: center;
}

.child {
  width: 25vw;
  min-width: 300px;
  height: 480px;
  background: linear-gradient(180deg, #FFD437 0%, rgba(255, 236, 169, 0.119792) 99.99%, rgba(255, 240, 185, 0) 100%);
  box-shadow: 11px 9px 9px rgba(0, 0, 0, 0.37);
  margin: 0 30px 30px 0;
  display: inline-block;
  border-radius: 14px;
  padding: 1rem 1rem;
  vertical-align: middle;
}

.timeLineChild {
  width: 30vw;
  min-width: 300px;
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: middle;
}

.headerImage{
  width: 100%;
  height: auto;
  min-height: 27vh;
  padding-bottom: 50px;
}

.comingsoon {
  border-radius: 14px;
  display: inline-block;
  margin: 5px 5px;
  line-height: 40px;
  padding: 5px 25px;
  color: rgba(245, 107, 141, 1);
  font-size: 19px;
  border: 1px solid rgba(245, 107, 141, 1);
}

@media only screen and (min-width: 768px) {
  .intro {
    color: #999999;
    margin: 0 35% 0 35%;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
  }

  .signUpTime{
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px
  }

  .h1 {
    color: black;
    font-size: 48px;
    font-weight: bold;
  }

  .h2 {
    color: #ff6f61;
    font-size: 30px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 767px) {
  .signUpTime{
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
  }

  .intro {
    color: #999999;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
  }

  .h1 {
    color: black;
    font-size: 26px;
    font-weight: 700;
  }

  .h2 {
    color: #ff6f61;
    font-size: 24px;
    font-weight: bold;
  }
}

.buttonText {
  color: #ff6f61;
  font-size: 30px;
  font-weight: bold;
}

.center{
  margin: auto;
  //width: 50%;
  padding: 10px;
  text-align: center
}

.content{
  text-indent: 30px;
  padding: 0 20px 0 20px;
}

.button {
  background: linear-gradient(92.92deg, #FFE27D 20.63%, #F47E59 92.37%);
  border-radius: 14px;
  display: inline-block;
  margin: 5px 5px;
  line-height: 40px;
  color: #fff;
  font-size: 19px;
  background-color: rgba(250, 215, 161, 1);
  border: none;
}

.buttonDisabled {
  background-color: rgba(250, 215, 161, 0.5);
  cursor: not-allowed;
}

.modalHeader {
  border-bottom: 1px solid rgba(235, 237, 239, 1);
}

.modalContent {
  padding: 10px 0 0 0;
}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  outline-style: none;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.inputForm {
  padding: 20px 10px 20px 10px;
}

.modalButton {
  display: inline-block;
  line-height: 40px;
  font-size: 19px;
  padding: 11px 20px;
  width: 50%;
}

.modalCenter{
  margin: auto;
  text-align: center;
  bottom: 0;
}

.pageContent{
  padding: 0 10vw 0 10vw;
}

.tourspage{display: flex;justify-content: center;flex-wrap: wrap;}

.hd{margin-top:30px;text-align:center;font-size:30px; color: rgba(244, 126, 89, 1); font-weight: bold}
</style>
